import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_NOTIFY_URL = process.env.VUE_APP_API_URL + "notification";

class NotifyService {
	getNotifications(page, status) {
		return axios.post(
			API_NOTIFY_URL + "/admin/paginated/" + page,
			{
				status: status,
			},
			{
				headers: authHeader(),
			}
		);
	}
	createNotification(notification) {
		return axios.post(API_NOTIFY_URL + "/", notification, {
			headers: authHeader(),
		});
	}
}

export default new NotifyService();
