<template>
	<div class="theme-dashboard-view">
		<h1>Notification</h1>
		<div class="d-flex justify-content-center" style="padding: 90px">
			<div class="text-center shadow rounded-3">
				<div class="px-5 py-4">
					<img
						src="@/assets/images/WhiteDebol.svg"
						style="height: 200px"
						alt="img"
					/>
				</div>
				<div class="h3 text-warning py-2 font-weight-bold">Coming Soon!</div>
			</div>
		</div>

		<!-- Loading Indicator -->
		<div>
			<div class="d-flex justify-content-center">
				<LoadingIndicator :active="loading" />
			</div>

			<div v-show="load_error">
				<div class="justify-content-center text-center text-danger my-4">
					<h4>{{ load_error }}</h4>
				</div>
			</div>
		</div>

		<!-- Main Content -->
		<main class="d-none flex-wrap h-100">
			<!-- Sidebar -->
			<div
				class="col-lg-4 col-12 border p-2 d-flex d-lg-block"
				style="overflow-y: auto"
			>
				<!-- Generator -->
				<div
					class="d-flex p-3"
					v-for="(notification, index) in prevNotification"
					:key="index"
				>
					<!-- Image -->
					<span class="d-flex justify-content-center">
						<div
							style="width: 70px; height: 70px"
							class="border rounded-circle"
						></div>
					</span>
					<!-- Text -->
					<div class="col-7 px-4">
						<strong>{{ notification.name }}</strong>
						<p>{{ notification.desc }}</p>
					</div>
				</div>
			</div>
			<!-- Create Notification Section-->
			<div class="col-lg-8 col-12 p-md-3 py-3">
				<div class="p-md-3">
					<label>Name of Notification</label><br />
					<input
						type="text"
						class="p-2 rounded"
						style="min-width: 450px"
						v-model="notificationName"
					/>
				</div>
				<div class="p-md-3">
					<label>Description</label><br />
					<textarea
						class="p-2 rounded"
						style="min-width: 450px; min-height: 200px; resize: none"
						v-model="notificationDesc"
					></textarea>
				</div>
				<div class="p-md-3">
					<label>Upload Picture</label><br />
					<input type="file" @change="setImage" accept="image/*" />
				</div>
				<div class="p-md-3">
					<label>Start Timestamp</label> <br />
					<input
						type="datetime-local"
						class="p-2 rounded"
						v-model="notificationStartTime"
					/>
				</div>

				<div class="p-md-3">
					<label>End Timestamp</label><br />
					<input
						type="datetime-local"
						class="p-2 rounded"
						v-model="notificationEndTime"
					/>
				</div>
				<div class="p-md-3">
					<button
						class="theme-button"
						style="height: 50px; width: 120px"
						@click="createNotification"
					>
						Submit
					</button>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
// Components
import LoadingIndicator from "@/components/LoadingIndicator.vue";
// Services
import NotifyService from "@/services/notification.js";

export default {
	name: "Notification",
	components: {
		LoadingIndicator,
	},
	data() {
		return {
			// Loading Settings
			loading: false,
			load_error: "",

			// Update Notification
			notificationID: null,

			// Previous Notifications
			// {
			// 		image: "",
			// 		name: "Name of Notification",
			// 		desc: "Culpa laboris do exercitation",
			// 	},
			prevNotification: [],
			// Create Notification Data
			notificationName: "",
			notificationDesc: "",
			notificationImage: "",
			notificationStartTime: null,
			notificationEndTime: null,
		};
	},

	methods: {
		// Collect all data and send it to backend to create a notification
		createNotification() {
			this.loading = true;
			this.load_error = "";

			const notification = {
				name: this.notificationName,
				description: this.notificationDesc,
				img: this.notificationImage,
				start_timestamp: this.notificationStartTime,
				end_timestamp: this.notificationEndTime,
			};
			NotifyService.createNotification(notification).then(
				(response) => {
					this.loading = false;
				},
				(error) => {
					this.loading = false;
					console.log("Error:", error);
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		// Get all previous notifications from database
		getPreviousNotifications(page) {
			// Set Loading indicator to running
			// Empty error text
			this.loading = true;
			this.load_error = "";

			NotifyService.getNotifications(page, true).then(
				(response) => {
					this.loading = false;
					console.log("NOTIFICATION:", response);
					this.prevNotification = response.data.notifications;
				},
				(error) => {
					this.loading = false;
					console.log("Error:", error);
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		// Set Image
		setImage(e) {
			const selectedImage = e.target.files[0];
			const reader = new FileReader();

			// Set itemImage once processing is done
			reader.onload = (e) => {
				this.notificationImage = e.target.result;
			};

			reader.readAsDataURL(selectedImage);
		},
	},
	mounted() {
		// this.getPreviousNotifications(1);
	},
};
</script>
